import { css } from 'linaria';
import { styled } from 'linaria/react';
import React from 'react';
import { Section } from '../../Shared/Section/Section';
import { theme } from '../../Shared/Theme/Theme';
import { KMWSide } from './components/KMWSide';
import { Perks } from './components/Perks';

export interface PerkSectionProps {
  className?: string;
}

const perksSideTile = css`
  width: 165px;
  background: ${theme.color.primary};
  @media (max-width: ${theme.breakpoints.lg}px) {
    display: none;
  }
`;

const PerkSectionInner = (props: PerkSectionProps) => {
  return (
    <Section className={props.className}>
      <KMWSide className={perksSideTile} />
      <Perks />
      <div className={perksSideTile} />
    </Section>
  );
};

export const PerkSection = styled(PerkSectionInner)`
  display: flex;
  @media (min-width: ${theme.breakpoints.lg}px) {
    min-height: 440px !important;
  }
`;
