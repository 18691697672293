import { styled } from 'linaria/react';
import { theme } from '../../Theme/Theme';

export const HeadingFour = styled.h4`
  font-size: 2.6rem;
  font-weight: 600;
  color: ${theme.color.primaryDark};
  padding: 4px 0;
  &.inverse {
    color: ${theme.color.light};
  }
`;
