import { css } from 'linaria';
import CalendarIcon from '../../../../assets/icons/kma_home_icon_1.svg';
import CollectiveIcon from '../../../../assets/icons/kma_home_icon_2.svg';
import FistIcon from '../../../../assets/icons/kma_home_icon_3.svg';
import { theme } from '../../../Shared/Theme/Theme';

export const perks = [
  {
    icon: CalendarIcon,
    heading: 'Pravidelné tréningy',
    content: `Pravidelnosť bola a je kľúčom k úspechu! V 
    Krav Maga Warrior máme pre teba ucelený 
    tréningový plán. Čím viac zo svojho času 
    investuješ, tým viac napreduješ.`,
    background: theme.color.secondary,
    headingClassNames: 'inverse',
    styles: css`
      background: ${theme.color.secondary};
    `
  },

  {
    icon: CollectiveIcon,
    headingClassNames: 'dark',
    heading: 'DOBRÝ KOLEKTÍV',
    content: `Môžeme sa pochváliť naozaj dobrou partiou. 
    Ľudia okrem tvrdého tréningu sa stávajú členmi 
    rodiny Krav Maga Warrior, vznikajú nové 
    kamarátstva, spoznávajú nových ľudí.`,
    styles: css`
      p {
        color: ${theme.color.primaryDark} !important;
      }
      background: ${theme.color.gray};
    `
  },
  {
    icon: FistIcon,
    headingClassNames: 'inverse',
    heading: 'EFEKTÍVNA OBRANA',
    content: `Nauč sa efektívne brániť! Zlepši svoje reakcie 
    v krízových situáciách. Či už sa jedná o teba, 
    tvoju rodinu, alebo niekoho kto potrebuje pomoc. 
    S dobrým človekom chodí dobrý úmysel.`,
    styles: css`
      background: ${theme.color.primary};
    `
  }
];
