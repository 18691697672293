import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { VscChevronLeft, VscChevronRight } from 'react-icons/vsc';
import { styled } from 'linaria/react';
import { theme } from '../../../Shared/Theme/Theme';
import { CarouselItem } from './CarouselItem';
import { trainings } from '../../../../assets/Content/useTrainings';

export interface CarouselProps {
  className?: string;
}

const TrainingTimesCarouselInner = (props: CarouselProps) => {
  return (
    <Carousel
      showThumbs={false}
      infiniteLoop={true}
      showStatus={false}
      renderArrowPrev={onClickHandler => (
        <VscChevronLeft onClick={onClickHandler} className="nav-icon back" />
      )}
      renderArrowNext={onClickHandler => (
        <VscChevronRight
          onClick={onClickHandler}
          className="nav-icon forward"
        />
      )}
      className={props.className}
    >
      {trainings.map((training, index) => (
        <CarouselItem key={index} training={training} />
      ))}
    </Carousel>
  );
};

export const TrainingTimesCarousel = styled(TrainingTimesCarouselInner)`
  .control-dots {
    @media (min-width: ${theme.breakpoints.md}px) {
      display: none;
    }
    z-index: 10;
  }
  .carousel .slide {
    background: none;
  }
  .nav-icon {
    position: absolute;
    top: 46%;
    -webkit-transform: translateY(-46%);
    transform: translateY(-46%);
    font-size: 6rem;
    z-index: 10;
    color: ${theme.color.primary};

    @media (max-width: ${theme.breakpoints.sm}px) {
      display: none;
    }
    &:hover {
      cursor: pointer;
    }

    &.back {
      left: -18px;
    }
    &.forward {
      right: -18px;
      z-index: 1000;
    }
  }
`;
