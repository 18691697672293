import { graphql, useStaticQuery } from 'gatsby';

export const useHeroImage = () => {
  const { hero } = useStaticQuery(graphql`
    query useHeroImage {
      hero: file(relativePath: { eq: "images/landing/hero/hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return hero;
};
