import React from 'react';
import { styled } from 'linaria/react';
import { muiTheme, theme } from '../../../Shared/Theme/Theme';
import { Training } from '../../../../assets/Content/useTrainings';
import { BorderedCardsBanner } from '../../../Shared/BorderedCardsBanner/BorderedCardsBanner';
import { Borders } from '../../../Shared/Borders/Borders';
import { PlaceLabel } from '../../../Shared/PlaceLabel/PlaceLabel';
import { HeadingWithNote } from '../../../Shared/HedingWithNote/HeadingWithNote';

export interface CarouselItemProps {
  training: Training;
  className?: string;
}

const carouselHeading = {
  heading: 'Pravidelné tréningy',
  noteText:
    'Privátne tréningy sú možné na vyžiadanie. O organizovaných podujatiach a akciách sa dozvieš na našej Facebook fan page.'
};

const CarouselItemInner = (props: CarouselItemProps) => {
  const { training } = props;
  return (
    <div className={props.className}>
      <Borders bottomLeft bottomRight>
        <div className="content">
          <HeadingWithNote
            heading={carouselHeading.heading}
            note={carouselHeading.noteText}
          />
          <BorderedCardsBanner bannerItems={training.times} />
          <PlaceLabel
            className="gym"
            place={training.place}
            address={training.address}
          />
        </div>
      </Borders>
    </div>
  );
};

export const CarouselItem = styled(CarouselItemInner)`
  padding-bottom: 30px;
  margin: auto;

  .content {
    width: 90%;
    margin: auto;
    padding-bottom: 4vh;
  }

  .gym {
    justify-content: center;
    width: 100%;
    position: absolute;
    bottom: -15px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    @media (max-width: ${theme.breakpoints.sm}px) {
      bottom: 10px !important;
    }

    ${muiTheme.breakpoints.down('sm')} {
      bottom: 0px !important;
    }
  }

  @media (min-width: ${theme.breakpoints.sm}px) {
    width: calc(100% - 200px);
  }
`;
