import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme/Theme';
import { HeadingFour } from '../Typography/HeadingFour/HeadingFour';
import { CornerBorder } from '../CornerBorder/CornerBorder';
import { Borders } from '../Borders/Borders';

export interface BorderedCardProps {
  className?: string;
  heading?: string;
  info?: string;
  children?: JSX.Element | JSX.Element[];
}

const BorderedCardInner = (props: BorderedCardProps) => {
  const { heading, info, children } = props;
  return (
    <div className={props.className}>
      <CornerBorder className="cb--top cb--left cb--secondary" />
      {children}
      <HeadingFour className="heading">{heading}</HeadingFour>
      <p dangerouslySetInnerHTML={{ __html: info }} />
      <CornerBorder className="cb--bottom cb--right cb--secondary" />
    </div>
  );
};

export const BorderedCard = styled(BorderedCardInner)`
  position: relative;
  height: 130px;
  text-align: center;
  max-width: 220px;
  margin: auto;
  font-size: 2rem;
  color: ${theme.color.primary};
  font-weight: 500;

  .heading {
    padding: 25px 0 16px 0px;
  }
  &.wide {
    max-width: 300px;
    height: 150px;
  }
`;
