import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../../Shared/Theme/Theme';
import { shade } from 'polished';

export interface ContactUsBannerItemProps {
  className?: string;
  icon: string;
  text: string;
  href: string;
}

const ContactUsBannerItemInner = (props: ContactUsBannerItemProps) => {
  const { text, href, icon } = props;
  return (
    <a target="_blank" rel="noopener noreferrer" href={href} className={props.className}>
      <img src={icon}></img>
      <div className="text">{text}</div>
    </a>
  );
};

export const ContactUsBannerItem = styled(ContactUsBannerItemInner)`
  display: flex;
  margin: 20px 0;

  :hover {
    img {
      transform: scale(${theme.hover.transformScale});
    }
    .text {
      color: ${shade(theme.hover.shade, theme.color.primaryDark)};
    }
  }

  img {
    max-height: 60px;
    max-width: 60px;
    object-fit: none;
    margin-right: 10px;
    margin-left: -10px;
    margin-bottom: 6px;
    transition: all ${theme.hover.transitionTime}ms ease-in-out;
  }

  .text {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 2.2rem;
    color: ${theme.color.primaryDark};
    transition: all ${theme.hover.transitionTime}ms ease-in-out;
  }
`;
