import { Grid } from '@material-ui/core';
import { styled } from 'linaria/react';
import React from 'react';
import { perks } from '../hooks/usePerks';
import { Perk } from './Perk';

export interface PerksProps {
  className?: string;
}

const PerksInner = (props: PerksProps) => {
  return (
    <Grid container className={props.className}>
      {perks.map((perk, index) => (
        <Grid key={index} item sm={12} md={4} container>
          <Perk {...perk} className={perk.styles} />
        </Grid>
      ))}
    </Grid>
  );
};

export const Perks = styled(PerksInner)``;
