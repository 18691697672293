export interface FaqItem {
  question: string;
  answer: string;
}

export const faqItems: FaqItem[] = [
  {
    question: `Kde prebiehajú tréningy? `,
    answer: `Tréningy prebiehajú podľa dohody v Základnej škole Kuchyňa plus vo Štvrtok, v utorok a štvrtok v Aircraft sport house (Krav Maga TCA) - pri letisku, v pondelok a stredu v Kittsee (AT) - Monkey fit.`
  },
  {
    question: `Koľko zaplatím za tréningy?`,
    answer: `Cena za tréning je: Základná škola Kuchyňa - 12€, Krav Maga TCA - 80€/mesiac, Monkey Fit - Kittsee (AT) - 80€/mesiac.`
  },
  {
    question: `Môžem sa pripojiť kedykoľvek? Som úplný začiatočník.`,
    answer: `Áno, pridať sa môžeš kedykoľvek. Najlepšie je informovať sa dopredu a dohodnúť sa s inštruktormi.`
  },
  {
    question: `Čo potrebujem na tréning?`,
    answer: `Základ je športové oblečenie (tričko s dlhým alebo krátkym rukávom - nie tielko), obuv na tatami, suspenzor, boxerské a/alebo sparringové rukavice, holenné chrániče, chránič na zuby. Ostatné chrániče ako helma, chrániče na predlaktia sú voliteľné.`
  },
  {
    question: `Ako prebiehajú tréningy?`,
    answer: `Rozcvička, opakovanie predošlých techník, nové techniky - podrobne rozobrané, využitie techník pod stresom a na konci kondičná časť.`
  }
];
