import { styled } from 'linaria/react';
import React from 'react';
import { ContainerCustom } from '../../Shared/ConatinerCustom/ContainerCustom';
import { Section } from '../../Shared/Section/Section';
import { HeadingTwo } from '../../Shared/Typography/HeadingTwo/HeadingTwo';

export interface LandingIntroSectionProps {
  className?: string;
}

const LandingIntroSectionInner = (props: LandingIntroSectionProps) => {
  return (
    <Section className={props.className}>
      <ContainerCustom>
        <HeadingTwo>NAUČ SA EFEKTÍVNE BRÁNIŤ</HeadingTwo>
        <p>
          Krav Maga je integrovaný systém sebaobrany, bojových schopností a
          taktiky, ako aj ochrany tretej osoby. Krav Maga bola pôvodne vyvinutá
          pre izraelskú armádu a následne bola prepracovaná pre použitie pre
          civilistov. Ako systém sebaobrany je unikátny okrem iného tým, že je
          stále otvorený a preto dynamicky reaguje na aktuálne hrozby, typy
          útokov a zmeny okolností. Krav Maga ti dodá sebavedomie a chladnú
          hlavu v napätých situáciách a zvyšuje odolnosť voči stresu aj v
          každodennom živote.
        </p>
      </ContainerCustom>
    </Section>
  );
};

export const LandingIntroSection = styled(LandingIntroSectionInner)`
  text-align: center;
`;
