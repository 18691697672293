import React from 'react';
import { styled } from 'linaria/react';
import { ContainerCustom } from '../../Shared/ConatinerCustom/ContainerCustom';
import { Section } from '../../Shared/Section/Section';
import { TrainingTimesCarousel } from './components/Carousel';

export interface CarouselSectionProps {
  className?: string;
}

const CarouselSectionInner = (props: CarouselSectionProps) => {
  return (
    <Section className={props.className}>
      <ContainerCustom>
        <TrainingTimesCarousel />
      </ContainerCustom>
    </Section>
  );
};

export const CarouselSection = styled(CarouselSectionInner)``;
