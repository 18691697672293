import MuiAccordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import withStyles from '@material-ui/core/styles/withStyles';
import { styled } from 'linaria/react';
import React from 'react';
import { FiMinus } from 'react-icons/fi';
import { HiPlus } from 'react-icons/hi';
import { muiTheme, theme } from '../../../Shared/Theme/Theme';
import { HeadingFour } from '../../../Shared/Typography/HeadingFour/HeadingFour';
import { faqItems } from '../hooks/useFaqItems';

export type FaqAccordionProps = React.HTMLAttributes<HTMLElement>;

const StyledAccordionSummary = styled(AccordionSummary)`
  font-weight: 600;
  font-size: 2.2rem;
  padding-left: 3% !important;
  color: ${theme.color.primaryDark} !important;
  .MuiAccordionSummary-content {
    margin: 0 !important;
    justify-content: space-between;
  }
  .MuiAccordionSummary-expandIcon {
    z-index: 100;
  }
`;
const StyledAccordionDetails = styled(AccordionDetails)`
  font-size: 2rem;
  color: ${theme.color.primaryDark};
  padding: 0 10% 3% 3% !important;

  ${muiTheme.breakpoints.down('sm')} {
    padding-right: 70px !important;
  }
`;
const StyledAccordion = withStyles({
  root: {
    border: `3px solid ${theme.color.grayDark}`,
    borderRadius: '0 !important',
    boxShadow: 'none',
    marginBottom: '3rem'
  },
  expanded: {}
})(MuiAccordion);

const CustomExpanderIcon = (props: any) => {
  return (
    <div className={props.className}>
      {props.expanded ? <FiMinus /> : <HiPlus />}
    </div>
  );
};

const StyledCustomExpanderIcon = styled(CustomExpanderIcon)`
  font-size: 2.9rem;
  font-weight: 300;
  margin: 10% -7% -10% 7%;
  color: ${theme.color.light};
`;

const ExpanderIconWrapper = styled.div`
  height: calc(100% + 6px);
  width: 63px;
  max-width: 15vw;
  position: absolute;
  right: -3px;
  top: -3px;
  background: ${theme.color.primary};
  &.expanded {
    background: ${theme.color.secondary};
  }
`;

const FaqAccordionInner: React.FC<FaqAccordionProps> = (
  props: FaqAccordionProps
) => {
  const [expanded, setExpanded] = React.useState('');
  const handleChange = (panel: any) => (event: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={props.className}>
      {faqItems.map(faqItem => (
        <StyledAccordion
          key={faqItem.question}
          expanded={expanded === faqItem.question}
          onChange={handleChange(faqItem.question)}
        >
          <StyledAccordionSummary
            expandIcon={
              <StyledCustomExpanderIcon
                expanded={expanded === faqItem.question}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <HeadingFour>{faqItem.question}</HeadingFour>
            <ExpanderIconWrapper
              className={expanded === faqItem.question ? 'expanded' : ''}
            />
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            {faqItem.answer}
            <ExpanderIconWrapper className="expanded" />
          </StyledAccordionDetails>
        </StyledAccordion>
      ))}
    </div>
  );
};

export const FaqAccordion = styled(FaqAccordionInner)<FaqAccordionProps>`
  margin-top: 2vh;
`;
