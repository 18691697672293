import { styled } from 'linaria/react';
import React from 'react';
import { theme } from '../../../Shared/Theme/Theme';

export interface KMWSideProps {
  className?: string;
}

const KMWSideInner = (props: KMWSideProps) => {
  return (
    <div className={props.className}>
      <span>KMW</span>
    </div>
  );
};

export const KMWSide = styled(KMWSideInner)`
  position: relative;
  background: ${theme.color.secondary} !important;

  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  span {
    font-size: 173px;
    font-weight: 700;
    transform: rotate(-90deg);

    -webkit-text-fill-color: ${theme.color.secondary};
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: ${theme.color.light};
  }
`;
