import { Container } from '@material-ui/core';
import { Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { css } from 'linaria';
import { styled } from 'linaria/react';
import React from 'react';
import { routes } from '../../../lib/routes';
import { KVButton } from '../../Shared/KVButton/KVButton';
import { muiTheme, theme } from '../../Shared/Theme/Theme';
import { useHeroImage } from './hooks/useHeroImage';

export interface HeroSectionProps {
  className?: string;
}

const HeroInner = styled.div`
  height: 900px;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  ${muiTheme.breakpoints.down('sm')} {
    height: 600px;
  }
`;

const HeroContentSection = styled.div`
  margin-bottom: 120px;
  h1 {
    text-transform: uppercase;
    color: ${theme.color.light};
    font-size: 5.5rem;
  }

  .hero-link {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  ${muiTheme.breakpoints.down('sm')} {
    margin-bottom: 30px;
  }
`;

const StyledBackgroundImage = css`
  &::before,
  &::after {
    filter: brightness(0.6);
  }
`;

export const HeroSection = (props: HeroSectionProps) => {
  const heroImage = useHeroImage();
  return (
    <BackgroundImage
      fluid={heroImage.childImageSharp.fluid}
      className={StyledBackgroundImage}
    >
      <Container>
        <HeroInner>
          <HeroContentSection>
            <h1>From fighter to WARRIOR</h1>
            <Link className="hero-link" to={routes.signUp.to}>
              <KVButton>Staň sa Krav Maga Warrior</KVButton>
            </Link>
          </HeroContentSection>
        </HeroInner>
      </Container>
    </BackgroundImage>
  );
};
