import { styled } from 'linaria/react';
import React from 'react';
import { HeadingThree } from '../../../Shared/Typography/HeadingThree/HeadingThree';
import { theme } from '../../../Shared/Theme/Theme';

export interface PerkProps {
  icon: string;
  heading: string;
  content: string;
  headingClassNames: string;

  className?: string;
}

export const Perk = (props: PerkProps) => {
  const { icon, heading, headingClassNames, content } = props;
  return (
    <Article {...props}>
      <img src={icon} />
      <HeadingThree className={headingClassNames}>{heading}</HeadingThree>
      <p>{content}</p>
    </Article>
  );
};

const Article = styled.article<Partial<PerkProps>>`
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;

  padding: 60px;
  text-align: center;
  color: ${theme.color.light};

  p {
    margin-top: 2rem;
    font-size: 1.9rem;
    font-weight: 200;
  }

  img {
    margin-bottom: 10px;
    max-height: 100px;
    object-fit: none;
  }

  @media (max-width: ${theme.breakpoints.lg}px) {
    padding: 50px;
  }
`;
