import React from 'react';
import { styled } from 'linaria/react';
import { Section } from '../../Shared/Section/Section';
import { ContactUsBanner } from './components/ContactUsBanner/ContactUsBanner';
import { ContainerCustom } from '../../Shared/ConatinerCustom/ContainerCustom';
import Grid from '@material-ui/core/Grid';
import { ContactUsForm } from './components/ContactUsForm/ContactUsForm';

export interface ContactUsSectionProps {
  className?: string;
}

const ContactUsSectionInner = (props: ContactUsSectionProps) => {
  return (
    <Section>
      <ContainerCustom>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <ContactUsBanner />
          </Grid>
          <Grid item xs={12} md={8}>
            <ContactUsForm />
          </Grid>
        </Grid>
      </ContainerCustom>
    </Section>
  );
};

export const ContactUsSection = styled(ContactUsSectionInner)``;
