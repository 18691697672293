import React from 'react';
import { styled } from 'linaria/react';
import { ContainerCustom } from '../../Shared/ConatinerCustom/ContainerCustom';
import { Section } from '../../Shared/Section/Section';
import { HeadingTwo } from '../../Shared/Typography/HeadingTwo/HeadingTwo';
import { FaqAccordion } from './components/FaqAccordion';

export type FaqAccordionSectionProps = React.HTMLAttributes<HTMLElement>;

const headingText = 'Časté otázky našich klientov';

const FaqAccordionSectionInner: React.FC<FaqAccordionSectionProps> = (
  props: FaqAccordionSectionProps
) => {
  return (
    <Section className={props.className}>
      <ContainerCustom>
        <HeadingTwo className="center">{headingText}</HeadingTwo>
        <FaqAccordion />
      </ContainerCustom>
    </Section>
  );
};

export const FaqAccordionSection = styled(FaqAccordionSectionInner)<
  FaqAccordionSectionProps
>``;
