import React from 'react';
import { styled } from 'linaria/react';
import { ContactUsBannerItem } from './ContactUsBannerItem';
import { HeadingTwo } from '../../../../Shared/Typography/HeadingTwo/HeadingTwo';
import { contactUsLinks } from '../../hooks/useContactUsLinks';

export interface ContactUsBannerProps {
  className?: string;
}

const ContactUsBannerInner = (props: ContactUsBannerProps) => {
  return (
    <div className={props.className}>
      <HeadingTwo>Napíš nám správu</HeadingTwo>
      {contactUsLinks.map(item => (
        <ContactUsBannerItem
          key={item.text}
          text={item.text}
          href={item.href}
          icon={item.icon}
        />
      ))}
    </div>
  );
};

export const ContactUsBanner = styled(ContactUsBannerInner)``;
