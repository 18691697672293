import React from 'react';
import Layout from '../components/Shared/Layout/Layout';
import { SEO } from '../components/Shared/SEO/SEO';

import { CarouselSection } from '../components/Landing/CarouselSection/CarouselSection';
import { PerkSection } from '../components/Landing/PerkSection/PerkSection';
// import { ReviewsSection } from '../components/landing/ReviewsSection/ReviewSection';
import { FaqAccordionSection } from '../components/Landing/FaqAccordionSection/FaqAccordionSection';
import { ContactUsSection } from '../components/Landing/ContactUsSection/ContactUsSection';
import { PartnersSection } from '../components/Shared/PartnersSection/PartnersSection';
import { HeroSection } from '../components/Landing/HeroSection/HeroSection';
import { LandingIntroSection } from '../components/Landing/LandingIntroSection/LandingIntroSection';

const IndexPage = () => (
  <Layout transparentHeader>
    <SEO/>

    <HeroSection />
    <LandingIntroSection />
    <CarouselSection />
    <PerkSection />
    {/* <ReviewsSection /> */}
    <FaqAccordionSection />
    <ContactUsSection />
    <PartnersSection />
  </Layout>
);

export default IndexPage;
